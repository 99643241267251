import React from 'react'
import baseStyled, {
  ThemeProvider as SCThemeProvider,
  ThemedStyledInterface
} from 'styled-components'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

// PRIMARY COLORS
const blue = '#0d47a1'
const yellow = '#ffc107'
const orange = '#ff9800'

// SECONDARY COLORS
const red = '#f44336'
const green = '#43a047'

// GRAYSCALE
const primaryText = '#424242'
const secondaryText = '#757575'
const tertiaryText = '#9e9e9e'
const disabled = '#e0e0e0'
const alias = '#fafafa'

// TINTS
const blueTint = '#e6ecf5'
const greenTint = '#e8f5e9'
const greyTint = '#f5f5f5'
const redTint = '#ffdedc'
// const purpleTint = '#ede7f6'
const purpleTint = '#f3e5f5'
//Fab
const navyBlue = '#062350'
const turquoise = '#1de9b6'

//Tags
const purple = '#ba68c8'

//Medals
const gold   = '#d6af36'
const silver = '#d7d7d7'
const bronze = '#a77044'

const zIndex = {
  // "default" z-index. Same as leaflet map.
  base: 1000,
  // Elements that should be above the general flow.
  aboveBase: 1100,
  // Elements that should be above elements that are above the general flow. Perhaps because they contain items with higher z-index requirements.
  aboveBasePriority: 1150,
  // Elements/widgets that interactively appear interactively
  interactiveMenu: 1200,
  // Popups/Drawers that take the main focus
  modal: 1300
}

export const theme = {
  colors: {
    primary: blue,
    secondary: green,
    error: red,

    textPrimary: primaryText,
    textSecondary: secondaryText,
    textTertiary: tertiaryText,
    alias,
    blue,
    red,
    green,
    yellow,
    lightYellow: '#FFF8E4',
    orange,
    purple,
    purpleTint,
    redTint,

    greenTint,
    blueTint,
    greyTint,

    black: '#000000',
    white: '#ffffff',
    grey: '#9E9E9E',
    lightGrey: '#E0E0E0',
    pageBackground: '#f5f5f5',
    navyBlue,
    turquoise,

    gold,
    silver,
    bronze
  },
  zIndex
} as const

type Theme = typeof theme
export type Color = keyof typeof theme.colors

const muiTheme = createMuiTheme({
  palette: {
    text: {
      primary: theme.colors.textPrimary,
      secondary: theme.colors.textSecondary
    },
    error: {
      main: theme.colors.error
    },
    primary: {
      main: theme.colors.primary
    },
    secondary: {
      main: theme.colors.secondary
    }
  }
})

export const ThemeProvider: React.FunctionComponent = ({ children }) => {
  return (
    <SCThemeProvider theme={theme}>
      <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
    </SCThemeProvider>
  )
}

export const styled = baseStyled as ThemedStyledInterface<Theme>
